.Contact {
    margin-top: -10px;
    padding-top: 10px;
    display: block;
    text-align: center;
    background-color: #f3e39b;
    color: #497d33;
}

.ContactTitle {
    text-align: center;
    background-color: #497d33;
    color: #f0dd83;
}

.ContactLogo {
    height: 30vh;
    margin-top: 30px;
    background-image: url("../assets/logo-furo.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
}

.About {
    height: 60vh;
    margin-top: 30px;
    background-image: url("../assets/about.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
}