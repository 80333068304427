.Karaoke {
    display: block;
    text-align: center;
    background-color: #f3e39b;
    color: #497d33;
}

.KaraokeTitle {
    text-align: center;
    background-color: #497d33;
    color: #f0dd83;
}

.KaraokeImage {
    height: auto;
    max-height: 100vh;
    width: auto;
    max-width: 100%;
}