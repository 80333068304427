.Concept {
    margin-top: -10px;
    padding-top: 10px;
    margin-bottom: -10px;
    padding-bottom: 10px;
    display: block;
    text-align: center;
    background-color: #f3e39b;
    color: #497d33;
}

.ConceptLogo {
    height: 30vh;
    margin-top: 30px;
    background-image: url("../assets/logo-furo.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
}

.ConceptImage {
    height: auto;
    max-height: 100vh;
    width: auto;
    max-width: 100%;
}